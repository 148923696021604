import React, { useState } from "react";
import Login from "./Login";
import Main from "./Main";

function App() {
  const [token, setToken] = useState("");
  const [role, setRole] = useState(null);

  return (
    <>
      {token ? (
        <Main token={token} role={role} setToken={setToken} />
      ) : (
        <Login
          setRole={setRole}
          onTokenSubmit={(submittedToken) => setToken(submittedToken)}
        />
      )}
    </>
  );
}

export default App;
