import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { apiClient } from "./apiClient";
import ErrorSnackbar from "./ErrorSnackbar";
import { getAccess } from "./roles";

const Login = ({ onTokenSubmit, setRole }) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await apiClient.getRoleInfo(token);
      if (response.error) {
        setError("Ошибка входа. Пожалуйста, проверьте ваш токен.");
      } else {
        if (!getAccess(response.role.name).read) {
          setError("У вас нет доступа.");
        } else {
          setRole(response.role.name);
          console.warn(`${JSON.stringify(response.role)}`);
          onTokenSubmit(token);
        }
      }
    } catch (error) {
      setError("Произошла ошибка при попытке входа.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          Вход
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="API Token"
            variant="outlined"
            fullWidth
            type="password"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 16 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Войти"}
          </Button>
        </form>
        <ErrorSnackbar
          open={Boolean(error)}
          handleClose={() => setError("")}
          message={error}
        />
      </Container>
    </Box>
  );
};

export default Login;
