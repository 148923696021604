import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  TextField,
} from "@mui/material";
import { localizations } from "./localizations";

const CategoryDialog = ({
  open,
  onClose,
  onSave,
  editingCategory,
  setError,
}) => {
  const [title, setTitle] = useState(
    localizations.reduce((acc, loc) => {
      acc[loc.code] = "";
      return acc;
    }, {})
  );
  const [imageUrl, setImageUrl] = useState(null);
  const [imageInput, setImageInput] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    if (editingCategory) {
      setTitle(
        localizations.reduce((acc, loc) => {
          acc[loc.code] = editingCategory.title[loc.code] || "";
          return acc;
        }, {})
      );
      if (editingCategory.imageUrl) {
        setLoadingImage(true);
        loadImage(
          `${editingCategory.imageUrl}?id=${randomNumberInRange(0, 10000)}`
        )
          .then(() => {
            setImageUrl(editingCategory.imageUrl);
            setLoadingImage(false);
          })
          .catch((error) => {
            console.error(error);
            setLoadingImage(false);
          });
      } else {
        setImageUrl(null);
      }
    } else {
      setTitle(
        localizations.reduce((acc, loc) => {
          acc[loc.code] = "";
          return acc;
        }, {})
      );
      setImageUrl(null);
    }
  }, [editingCategory]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error("Image load failed"));
    });
  };

  const handleSave = async () => {
    onClose();
    setImageInput(null);
    await onSave({ ...editingCategory, title, imageUrl }, imageInput);
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleClose = () => {
    onClose();
    setImageInput(null);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {editingCategory ? "Изменить категорию" : "Добавить категорию"}
      </DialogTitle>
      <DialogContent>
        {localizations.map((loc) => (
          <TextField
            key={loc.code}
            label={`${loc.label}`}
            value={title[loc.code]}
            onChange={(e) => setTitle({ ...title, [loc.code]: e.target.value })}
            fullWidth
            margin="dense"
          />
        ))}
        {loadingImage ? (
          <CircularProgress
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "30px",
              height: "30px",
              marginBottom: "16px",
            }}
          />
        ) : imageUrl ? (
          <Box component="div" marginBottom={2}>
            <Box
              component="img"
              src={`${imageUrl}`}
              alt={`Preview of ${
                editingCategory ? editingCategory.title : "new"
              } category`}
              style={{ maxWidth: "100%", maxHeight: "200px" }}
            />
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              height: "200px",
              border: "1px dashed #ccc",
              marginBottom: "16px",
              borderRadius: "4px",
            }}
          >
            Нет изображения
          </div>
        )}

        <FormControl fullWidth margin="dense">
          <Input
            id="imageInput"
            type="file"
            accept=".png"
            style={{ display: "none" }}
            onChange={(ev) => {
              const file = ev.target.files[0];
              if (file.type !== "image/png") {
                setError("Неверный формат файла, пожалуйста выберите PNG");
              } else {
                setImageInput(file);
              }
            }}
          />
          <label htmlFor="imageInput">
            <Button
              style={{ width: "50%" }}
              component="span"
              variant="contained"
              color="primary"
            >
              Выберите изображение
            </Button>
          </label>
          {imageInput && (
            <span style={{ marginLeft: "8px" }}>{imageInput.name}</span>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
