import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import { getAccess } from "./roles";
import { getFirstFilledLocalization } from "./utils";

const CategoryDrawer = ({
  categories,
  selectedCategory,
  onCategorySelect,
  onEdit,
  onDelete,
  onAddCategory,
  role,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const openDeleteDialog = (id) => {
    setSelectedCategoryId(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedCategoryId(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    onDelete(selectedCategoryId);
    closeDeleteDialog();
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 300,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 300,
          backgroundColor: "#f5f5f5",
        },
      }}
    >
      <Typography
        variant="h6"
        align="center"
        sx={{ marginTop: 2, marginBottom: 2 }}
        style={{ textTransform: "uppercase", fontWeight: "600" }}
      >
        Категории
      </Typography>
      <List>
        {categories.map((category) => (
          <ListItem
            button
            key={category.id}
            onClick={() => onCategorySelect(category.id)}
            sx={{
              backgroundColor:
                selectedCategory === category.id
                  ? "rgba(12,43,68,0.3)"
                  : "inherit",
              "&:hover": {
                backgroundColor:
                  selectedCategory === category.id
                    ? "rgba(12,43,68,0.4)"
                    : "rgba(12,43,68,0.1)",
              },
            }}
            onMouseEnter={() => setHoveredCategory(category.id)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <ListItemText
              primary={getFirstFilledLocalization(category.title)}
              sx={{
                color: selectedCategory === category.id ? "white" : "inherit",
              }}
            />
            {hoveredCategory === category.id && getAccess(role).write && (
              <ListItemSecondaryAction
                onMouseEnter={() => setHoveredCategory(category.id)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <IconButton edge="end" onClick={() => onEdit(category)}>
                  <Edit />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => openDeleteDialog(category.id)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        {getAccess(role).createCategory && (
          <ListItem>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={onAddCategory}
              fullWidth
            >
              Создать Категорию
            </Button>
          </ListItem>
        )}
      </List>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Удалить категорию?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите удалить эту категорию? Это действие нельзя
            отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default CategoryDrawer;
