import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  Typography,
} from "@mui/material";
import BookListItem from "./BookListItem";
import { localizations } from "./localizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getFirstFilledLocalization } from "./utils";

const BookList = ({
  books,
  onEdit,
  onDelete,
  selectedCategory,
  categories,
  role,
}) => {
  const [hoveredBookId, setHoveredBookId] = useState(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);

  const openDeleteDialog = (id) => {
    setSelectedBookId(id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedBookId(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    onDelete(selectedBookId);
    closeDeleteDialog();
  };

  const getCategoryTitle = (categoryId) => {
    const category = categories.find((category) => category.id === categoryId);
    return category ? getFirstFilledLocalization(category.title) : "";
  };

  return (
    <Box>
      {selectedCategory ? (
        <Typography
          variant="h6"
          component="div"
          marginBottom={2}
          marginTop={2}
          style={{ textTransform: "uppercase", fontWeight: "600" }}
        >
          Категория: {getCategoryTitle(selectedCategory)}
        </Typography>
      ) : (
        <Box
          border={0}
          borderRadius={4}
          marginBottom={2}
          marginTop={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="50vh"
          sx={{
            backgroundColor: "#f5f5f5",
          }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
          <Typography variant="h6" component="div" marginTop={1}>
            Выберите категорию
          </Typography>
          <Typography
            variant="h8"
            component="div"
            marginTop={1}
            marginBottom={1}
          >
            чтобы редактировать или создавать книги
          </Typography>
        </Box>
      )}
      <List>
        {books.map((book, index) => (
          <BookListItem
            book={book}
            index={index}
            hoveredBookId={hoveredBookId}
            setHoveredBookId={setHoveredBookId}
            role={role}
            openDeleteDialog={openDeleteDialog}
            onEdit={onEdit}
            localizations={localizations}
          />
        ))}
      </List>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Удалить Книгу?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите удалить эту книгу? Это действие нельзя
            отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookList;
