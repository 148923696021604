import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { apiClient } from "./apiClient";
import CategoryDrawer from "./CategoryDrawer";
import BookList from "./BookList";
import CategoryDialog from "./CategoryDialog";
import BookDialog from "./BookDialog";
import { Add } from "@mui/icons-material";
import ErrorSnackbar from "./ErrorSnackbar";
import { getAccess } from "./roles";

const Main = ({ token, role, setToken }) => {
  const [loading, setLoading] = useState(false);
  const [loadingReason, setLoadingReason] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [books, setBooks] = useState([]);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [bookDialogOpen, setBookDialogOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingBook, setEditingBook] = useState(null);
  const [error, setError] = useState(null);
  const [errorTimer, setErrorTimer] = useState(null);

  useEffect(() => {
    setLoading(true);
    setLoadingReason("Обновление списка категоиий");
    apiClient
      .getAllCategories(token)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    if (error) {
      if (errorTimer != null) {
        clearTimeout(errorTimer);
        setErrorTimer(null);
      }
      setErrorTimer(
        setTimeout(() => {
          setError(null);
        }, 5000)
      );
    }
    // eslint-disable-next-line
  }, [error]);

  const handleCategorySelect = async (categoryId) => {
    setSelectedCategory(categoryId);
    setLoading(true);
    setLoadingReason("Получение списка книг активной категории");

    try {
      const result = await apiClient.getBooksByCategory(token, categoryId);
      // setError(JSON.stringify(result))
      setBooks(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySave = async (category, image) => {
    setLoading(true);
    setLoadingReason("Сохранение категории");
    let savedCategory = null;
    try {
      if (editingCategory) {
        savedCategory = await apiClient.updateCategory(
          token,
          editingCategory.id,
          category
        );
      } else {
        savedCategory = await apiClient.createCategory(token, category);
        console.log(JSON.stringify(savedCategory));
        setEditingCategory(savedCategory);
      }

      if (image && savedCategory) {
        setLoadingReason("Сохранеие изображения категории");
        await apiClient.uploadCategoryImage(token, savedCategory.id, image);
      }
      setLoadingReason("Обновление списка категоиий");
      const result = await apiClient.getAllCategories(token);
      setCategories(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setCategoryDialogOpen(false);
      setEditingCategory(null);
    }

    return savedCategory;
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setCategoryDialogOpen(true);
  };

  const handleDeleteCategory = async (categoryId) => {
    setLoading(true);

    try {
      setLoadingReason("Удаление категории");
      await apiClient.deleteCategory(token, categoryId);
      setLoadingReason("Обновление списка категоиий");
      const result = await apiClient.getAllCategories(token);
      setCategories(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBookSave = async (book, localizationData) => {
    setLoading(true);
    let savedBook = null;
    try {
      if (editingBook) {
        setLoadingReason("Сохранение книги");
        savedBook = await apiClient.updateBook(token, editingBook.id, book);
      } else {
        if (getAccess(role).createBooks) {
          book.categoryId = selectedCategory;
          setLoadingReason("Создание книги");
          savedBook = await apiClient.createBook(token, book);
          setEditingBook(savedBook);
        } else {
          setLoading(false);
          return;
        }
      }

      if (savedBook) {
        for (const locCode of Object.keys(localizationData)) {
          const locData = localizationData[locCode];

          if (locData.text && getAccess(role).uploadText) {
            setLoadingReason(
              `Сохранение текста книги для локализации [${locCode}]`
            );
            await apiClient.uploadTextFile(
              token,
              selectedCategory,
              savedBook.id,
              locCode,
              new Blob([locData.text], { type: "text/plain" })
            );
          }

          if (
            locData.audioFile &&
            locData.audioFile instanceof File &&
            getAccess(role).uploadAudio
          ) {
            setLoadingReason(
              `Сохранение аудио файла книги для локализации [${locCode}]`
            );
            await apiClient.uploadAudioFile(
              token,
              selectedCategory,
              savedBook.id,
              locCode,
              locData.audioFile
            );
          }
        }
      }
      setLoadingReason("Обновление списка книг в активной категории");
      const result = await apiClient.getBooksByCategory(
        token,
        selectedCategory
      );
      setBooks(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setBookDialogOpen(false);
      setEditingBook(null);
    }

    return savedBook;
  };

  const handleEditBook = (book) => {
    setEditingBook(book);
    setBookDialogOpen(true);
  };

  const handleDeleteBook = async (bookId) => {
    setLoading(true);
    try {
      setLoadingReason("Удаление выбранной книги");
      await apiClient.deleteBook(token, bookId);
      setLoadingReason("Обновление списка книг в активной категории");
      const result = await apiClient.getBooksByCategory(
        token,
        selectedCategory
      );
      setBooks(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            gap: 2,
          }}
        >
          <Typography>{loadingReason}</Typography>
          <CircularProgress size={30} />
        </Box>
      )}
      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CategoryDrawer
              categories={categories}
              selectedCategory={selectedCategory}
              onCategorySelect={handleCategorySelect}
              onEdit={handleEditCategory}
              onDelete={handleDeleteCategory}
              onAddCategory={() => setCategoryDialogOpen(true)}
              role={role}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <BookList
              books={books}
              onEdit={handleEditBook}
              onDelete={handleDeleteBook}
              selectedCategory={selectedCategory}
              categories={categories}
              role={role}
            />
            {selectedCategory && getAccess(role).createBooks && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="220px"
                height="40px"
                bgcolor={"primary.main"}
                color="white"
                borderRadius={1}
                marginTop={2}
                onClick={() => setBookDialogOpen(true)}
                style={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  cursor: "pointer",
                }}
              >
                <Add />
                <Typography
                  variant="h9"
                  component="div"
                  marginLeft={1}
                  style={{ textTransform: "uppercase" }}
                >
                  Создать книгу
                </Typography>
              </Box>
            )}

            {role && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="300px"
                height="40px"
                borderRadius={1}
                marginTop={2}
                onClick={() => setToken(null)}
                style={{
                  position: "fixed",
                  top: 0,
                  right: 16,
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  marginLeft={1}
                  style={{ textTransform: "uppercase", fontWeight: "600" }}
                >
                  роль:
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  marginLeft={1}
                  style={{ textTransform: "uppercase", fontWeight: "600" }}
                >
                  {getAccess(role).name}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      <CategoryDialog
        open={categoryDialogOpen}
        onClose={() => {
          setCategoryDialogOpen(false);
          setEditingCategory(null);
        }}
        onSave={handleCategorySave}
        editingCategory={editingCategory}
        setError={setError}
      />
      <BookDialog
        open={bookDialogOpen}
        onClose={() => {
          setBookDialogOpen(false);
          setEditingBook(null);
        }}
        onSave={handleBookSave}
        editingBook={editingBook}
        setError={setError}
        role={role}
      />
      <ErrorSnackbar
        open={error !== null}
        message={error}
        onClose={() => setError(null)}
      />
    </Container>
  );
};

export default Main;
