import React from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import { Audiotrack, Delete, Description } from "@mui/icons-material";
import { getFirstFilledLocalization } from "./utils";
import { getAccess } from "./roles";

const BookListItem = ({
  book,
  hoveredBookId,
  setHoveredBookId,
  role,
  openDeleteDialog,
  onEdit,
  localizations,
  index,
}) => {
  const renderFileInfo = (fileInfo, localizations) => {
    return localizations.map((loc) => (
      <Box
        key={loc.code}
        component="span"
        marginLeft={1}
        marginRight={1}
        bgcolor={
          fileInfo[loc.code] ? "rgba(46,164,98,0.7)" : "rgba(203,78,78,0.7)"
        }
        color="white"
        paddingX={0.5}
        borderRadius={1}
        // border={0.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="30px"
      >
        {loc.code}
      </Box>
    ));
  };

  return (
    <ListItem
      button
      key={book.id}
      onMouseEnter={() => setHoveredBookId(book.id)}
      onMouseLeave={() => setHoveredBookId(null)}
      sx={{
        borderWidth: 0,
        borderStyle: "solid",
        borderRadius: 2,
        marginBottom: 1,
        bgcolor:
          Math.round(index % 2) === 0 ? "rgb(0,0,0,0.1)" : "rgb(0,0,0,0.05)",
      }}
      onClick={() => onEdit(book)}
    >
      <Box component="div">
        <Box style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              marginBottom: 2,
            }}
          >
            {getFirstFilledLocalization(book.title)}
          </Typography>
        </Box>
        <Box style={{ display: "flex" }} alignItems="center">
          <Description fontSize="small" style={{ marginRight: "8px" }} />
          {renderFileInfo(book.textFile, localizations)}
        </Box>
        <Box style={{ display: "flex" }} alignItems="center" marginTop={1}>
          <Audiotrack fontSize="small" style={{ marginRight: "8px" }} />
          {renderFileInfo(book.audioFile, localizations)}
        </Box>
      </Box>
      {hoveredBookId === book.id && (
        <ListItemSecondaryAction
          onMouseEnter={() => setHoveredBookId(book.id)}
          onMouseLeave={() => setHoveredBookId(null)}
        >
          {getAccess(role).write && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => openDeleteDialog(book.id)}
            >
              <Delete />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default BookListItem;
