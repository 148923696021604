const API_URL = "https://us-central1-night-tale-e1322.cloudfunctions.net";

async function callApi(endpoint, method, apiKey, body) {
  body.key = apiKey;

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(`${API_URL}/${endpoint}`, options);
    return await response.json();
  } catch (e) {
    console.error(JSON.stringify(e));
  }
}

export const apiClient = {
  getRoleInfo: async (apiKey) =>
    callApi("getRoleInfoFunction", "POST", apiKey, {}),
  createCategory: async (apiKey, category) =>
    callApi("createCategoryFunction", "POST", apiKey, { category }),
  getAllCategories: async (apiKey) =>
    callApi("getAllCategoriesFunction", "POST", apiKey, {}),
  getCategoryById: async (apiKey, categoryId) =>
    callApi("getCategoryByIdFunction", "POST", apiKey, { id: categoryId }),
  updateCategory: async (apiKey, categoryId, updatedData) =>
    callApi("updateCategoryFunction", "POST", apiKey, {
      id: categoryId,
      updatedCategory: updatedData,
    }),
  deleteCategory: async (apiKey, categoryId) =>
    callApi("deleteCategoryFunction", "POST", apiKey, { id: categoryId }),
  createBook: async (apiKey, book) =>
    callApi("createBookFunction", "POST", apiKey, { book: book }),
  getAllBooks: async (apiKey) =>
    callApi("getAllBooksFunction", "POST", apiKey, {}),
  getBookById: async (apiKey, bookId) =>
    callApi("getBookByIdFunction", "POST", apiKey, { id: bookId }),
  getBooksByCategory: async (apiKey, categoryId) =>
    callApi("getBooksByCategoryFunction", "POST", apiKey, {
      categoryId: categoryId,
    }),
  updateBook: async (apiKey, bookId, updatedData) =>
    callApi("updateBookFunction", "POST", apiKey, {
      id: bookId,
      updatedBook: updatedData,
    }),
  deleteBook: async (apiKey, bookId) =>
    callApi("deleteBookFunction", "POST", apiKey, { id: bookId }),
  uploadAudioFile: async (apiKey, categoryId, bookId, language, audioFile) => {
    const formData = new FormData();
    formData.append("key", apiKey);
    formData.append("categoryId", categoryId);
    formData.append("bookId", bookId);
    formData.append("language", language);
    formData.append("audio", audioFile);

    const response = await fetch(`${API_URL}/uploadAudioFileFunction`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`${error.error}: ${error.message}`);
    }

    return await response.json();
  },
  uploadTextFile: async (apiKey, categoryId, bookId, language, textFile) => {
    const formData = new FormData();
    formData.append("key", apiKey);
    formData.append("categoryId", categoryId);
    formData.append("bookId", bookId);
    formData.append("language", language);
    formData.append("text", textFile);

    const response = await fetch(`${API_URL}/uploadTextFileFunction`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`${error.error}: ${error.message}`);
    }

    return await response.json();
  },
  getChanges: async (apiKey, pageSize, nextPageToken) =>
    callApi("getChangesFunction", "POST", apiKey, {
      pageSize: pageSize,
      nextPageToken: nextPageToken,
    }),
  uploadCategoryImage: async (apiKey, categoryId, imageFile) => {
    const formData = new FormData();
    formData.append("key", apiKey);
    formData.append("categoryId", categoryId);
    formData.append("image", imageFile);

    const response = await fetch(`${API_URL}/uploadCategoryImageFunction`, {
      method: "POST",
      body: formData,
    });

    return await response.json();
  },
};
