export const roles = [
  {
    name: "Admin",
    read: true,
    write: true,
    createBooks: true,
    saveBooks: true,
    uploadText: true,
    uploadAudio: true,
    createCategory: true,
  },
  {
    name: "Content Manager",
    read: true,
    write: false,
    createBooks: true,
    saveBooks: true,
    uploadText: true,
    uploadAudio: false,
  },
  {
    name: "User",
    read: false,
    write: false,
  },
  {
    name: "Audio Manager",
    read: true,
    write: false,
    createBooks: false,
    saveBooks: false,
    uploadText: false,
    uploadAudio: true,
  },
];

export const getAccess = (role) => {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].name === role) {
      return roles[i];
    }
  }
  return null;
};
