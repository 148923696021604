import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { localizations } from "./localizations";
import { getAccess } from "./roles";

const emptyLocalizationData = {
  title: "",
  textFile: "",
  audioFile: "",
};

const BookDialog = ({ open, onClose, onSave, editingBook, setError, role }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [audioFileURL, setAudioFileURL] = useState(null);

  const [localizationData, setLocalizationData] = useState(
    localizations.reduce((acc, loc) => {
      acc[loc.code] = { ...emptyLocalizationData };
      return acc;
    }, {})
  );

  useEffect(() => {
    if (editingBook) {
      setActiveTab(0);
      setLocalizationData(
        localizations.reduce((acc, loc) => {
          acc[loc.code] = {
            title: editingBook.title[loc.code] || "",
            textFile: editingBook.textFile[loc.code] || "",
            audioFile: editingBook.audioFile[loc.code] || "",
          };
          return acc;
        }, {})
      );
    } else {
      setLocalizationData(
        localizations.reduce((acc, loc) => {
          acc[loc.code] = { ...emptyLocalizationData };
          return acc;
        }, {})
      );
    }
  }, [editingBook]);

  useEffect(() => {
    if (editingBook) {
      localizations.forEach(async (loc) => {
        if (editingBook.textFile[loc.code]) {
          try {
            const response = await fetch(
              `${editingBook.textFile[loc.code]}?id=${randomNumberInRange()}`
            );
            const text = await response.text();
            setLocalizationData((prevData) => ({
              ...prevData,
              [loc.code]: {
                ...prevData[loc.code],
                text: text,
              },
            }));
          } catch (error) {
            console.error(`Error fetching text file for ${loc.code}:`, error);
          }
        }
      });
    }
  }, [editingBook]);

  useEffect(() => {
    const loc = localizations[activeTab];
    let source = null;
    if (localizationData[loc.code].audioFile instanceof File) {
      source = URL.createObjectURL(localizationData[loc.code].audioFile);
    } else if (editingBook && editingBook.audioFile[loc.code]) {
      source = editingBook.audioFile[loc.code];
    } else {
      source = localizationData[loc.code].audioFile;
    }
    setAudioFileURL(source);
  }, [activeTab, editingBook, localizationData]);

  const handleSave = async () => {
    onClose();

    const bookData = {
      ...editingBook,
      title: {},
    };

    if (!bookData.audioFile) {
      bookData.audioFile = {};
    }

    if (!bookData.textFile) {
      bookData.textFile = {};
    }

    for (const locCode of Object.keys(localizationData)) {
      if (localizationData[locCode]) {
        bookData.title[locCode] = localizationData[locCode].title;
      } else {
        bookData.title[locCode] = emptyLocalizationData.title;
      }

      if (bookData.audioFile[locCode]) {
        bookData.audioFile[locCode] = editingBook.audioFile[locCode];
      } else {
        bookData.audioFile[locCode] = "";
      }
      if (bookData.textFile[locCode]) {
        bookData.textFile[locCode] = editingBook.textFile[locCode];
      } else {
        bookData.textFile[locCode] = "";
      }
    }

    // передаем bookData и localizationData в качестве аргументов для onSave
    await onSave(bookData, localizationData);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {editingBook ? "Редактировать книгу" : "Создать книгу"}
      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          {localizations.map((loc, index) => (
            <Tab key={loc.code} label={loc.label} value={index} />
          ))}
        </Tabs>
        {localizations.map((loc, index) => (
          <div key={loc.code} hidden={activeTab !== index}>
            <TextField
              label={`Название книги`}
              value={localizationData[loc.code].title}
              onChange={(e) =>
                setLocalizationData({
                  ...localizationData,
                  [loc.code]: {
                    ...localizationData[loc.code],
                    title: e.target.value,
                  },
                })
              }
              fullWidth
              margin="dense"
              InputProps={{
                readOnly: !getAccess(role).uploadText,
              }}
            />
            <TextField
              label={`Текст книги`}
              value={localizationData[loc.code].text}
              onChange={(e) =>
                setLocalizationData({
                  ...localizationData,
                  [loc.code]: {
                    ...localizationData[loc.code],
                    text: e.target.value,
                  },
                })
              }
              fullWidth
              margin="dense"
              multiline
              InputProps={{
                readOnly: !getAccess(role).uploadText,
              }}
              rows={12}
            />
            {audioFileURL && (
              <Box marginTop={2}>
                <audio controls>
                  <source src={audioFileURL} type="audio/mpeg" />
                  Ваш браузер не поддерживает тег audio.
                </audio>
              </Box>
            )}
            {getAccess(role).uploadAudio && (
              <FormControl fullWidth margin="dense">
                <Input
                  id={`${loc.code}-audioInput`}
                  type="file"
                  accept=".mp3"
                  style={{ display: "none" }}
                  onChange={(ev) => {
                    const file = ev.target.files[0];
                    if (file.type !== "audio/mpeg") {
                      setError(
                        "Неверный формат файла, пожалуйста выберите MP3"
                      );
                      // alert('Invalid file type. Please select an MP3 file.');
                    } else {
                      setLocalizationData({
                        ...localizationData,
                        [loc.code]: {
                          ...localizationData[loc.code],
                          audioFile: file,
                        },
                      });
                    }
                  }}
                />
                <label htmlFor={`${loc.code}-audioInput`}>
                  <Button
                    style={{ width: "50%" }}
                    component="span"
                    variant="contained"
                    color="primary"
                  >
                    Выберите аудио файл
                  </Button>
                </label>
                {localizationData[loc.code] &&
                  localizationData[loc.code].audioFile && (
                    <span style={{ marginLeft: "8px" }}>
                      {localizationData[loc.code].audioFile.name}
                    </span>
                  )}
              </FormControl>
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookDialog;
